import React, { useContext, useEffect, useState } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Context, Provider } from "./components/context/index"

import GetSheets from "./components/GetSheets/index"
import Header from "./components/Header/index"
import Home from "./components/Pages/Home/index"
import AllFlavours from "./components/Pages/AllFlavours/index"
import Products from "./components/Pages/Products"
import Cart from "./components/Pages/Cart/index"
import Footer from "./components/Footer/index"
import FlavourInfo from "./components/Pages/FlavourInfo"
import ScrollToTop from "./utils/ScrollToTop"
import Shop from "./components/Pages/Shop"
import OrderForm from "./components/Pages/Shop/OrderForm"
import ThankYou from "./components/Pages/ThankYou"

import "./style/style.css"

const App = () => {
    const [cartCount, setCartCount] = useState(0)
    // const [{ flavours, date }, dispatch] = useContext(Context)
    const [checkout, setCheckout] = useState(JSON.parse(sessionStorage.getItem("checkout")) || {})

    useEffect(() => {
        // add up the length of the checkout arrays to count how many things are in cart
        let count = 0
        for (let i = 0; i < Object.values(checkout).length; i++) {
            Object.values(Object.values(checkout)[i]).forEach((item, i) => {
                count += item.length || 0
            })
        }
        setCartCount(count)
    }, [checkout])

    // useEffect(() => {
        // const nextOrderDate2 = weeklyFlavours => {
        //     return weeklyFlavours
        //         .filter(flavour => {
        //             // undefined argument is getting the date for current day
        //             const isFutureFlavour =
        //                 momentInt(flavour["Date"], "DDD") > momentInt(undefined, "DDD") ||
        //                 momentInt(flavour["Date"], "YY") > momentInt(undefined, "YY")

        //             const isMoreThanTwoDaysBeforeFlavourDate = momentInt(flavour["Date"], "DDD") - momentInt(undefined, "DDD") > 2

        //             const twoDaysBeforeFlavourDate = momentInt(undefined, "DDD") === momentInt(flavour["Date"], "DDD") - 2

        //             const isBeforeEightPm = momentInt(undefined, "H") < 20

        //             return (
        //                 (isFutureFlavour && isMoreThanTwoDaysBeforeFlavourDate) ||
        //                 (isFutureFlavour && twoDaysBeforeFlavourDate && isBeforeEightPm)
        //             )
        //         })
        //         .reduce((acc, curr) => {
        //             if (!acc.includes(curr["Date"])) acc.push(curr["Date"])
        //             return acc
        //         }, [])
        // }
        // nextOrderDate2(flavours.weekly)
    // }, [])

    return (
        <Provider>
            <Router>
                <ScrollToTop />
                <GetSheets setCheckout={setCheckout} />
                <Header cartCount={cartCount} />
                <Switch>
                    <Route path="/thankyou">
                        <ThankYou />
                    </Route>
                    <Route path="/flavours/:flavourName" component={FlavourInfo} />
                    <Route path="/flavours">
                        <AllFlavours />
                    </Route>
                    <Route path="/products">
                        <Products />
                    </Route>
                    <Route
                        path="/shop/:product"
                        component={props => <OrderForm {...props} checkout={checkout} setCheckout={setCheckout} />}
                    />
                    <Route path="/shop">
                        <Shop />
                    </Route>
                    <Route path="/cart">
                        <Cart checkout={checkout} setCheckout={setCheckout} setCartCount={setCartCount} />
                    </Route>
                    <Route path="/">
                        <Home checkout={checkout} />
                    </Route>
                </Switch>
                <Footer />
            </Router>
        </Provider>
    )
}

export default App
