import React, { useContext, useEffect } from 'react'
import { Context } from '../context'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import {
  CLIENT_EMAIL,
  PRIVATE_KEY,
  SPREADSHEET_ID,
  MAC_FLAVOURS_SHEET_ID,
  WEEKLY_FLAVOURS_SHEET_ID,
  GOOGLE_PRICES_SHEET_ID,
  GOOGLE_DELIVERY_ID,
  GOOGLE_ORDERS_ID,
  GOOGLE_COUPONS_ID
} from '../../utils/configs'
import { nextOrderDates } from '../../utils/getDates'

function GetSheets({ setCheckout }) {
  const [{ loading, flavours, cart }, dispatch] = useContext(Context)

  useEffect(() => {
    // set a default image for flavours
    flavours.all.forEach(item => {
      const validExtensions = ['jpg', 'png']
      const extension = (item['Image'] && item['Image'].split('.').reverse()[0]) || ''

      if (!Boolean(item['Image']) || !validExtensions.includes(extension.toLowerCase())) {
        item['Image'] = 'https://imgur.com/hY9uaS2.jpg'
      }
    })

    if (Object.keys(cart).length === 0) {
      const initialCart = () => {
        const weeklyCart = {
          'Box of 5': [],
          'MAC-A-GRAM': [],
          'Batch of 12': [],
          'Catering + Bulk': []
        }

        const result = nextOrderDates(flavours.weekly).reduce((acc, curr) => {
          if (!acc[curr]) acc[curr] = weeklyCart
          return acc
        }, {})

        return result
      }

      dispatch({ type: 'setCart', data: initialCart() })
      setCheckout(initialCart())
    }
  }, [flavours])

  useEffect(() => {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID)
    const getGoogleSheets = async () => {
      try {
        dispatch({ type: 'loading', data: true })

        await doc.useServiceAccountAuth({
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY
        })
        // loads document properties and worksheets
        await doc.loadInfo()
        // get data in each row as objects in an array
        const weekly = await doc.sheetsById[WEEKLY_FLAVOURS_SHEET_ID].getRows()

        dispatch({
          type: 'setFlavours',
          data: {
            all: await doc.sheetsById[MAC_FLAVOURS_SHEET_ID].getRows(),
            weekly: weekly
          }
        })

        dispatch({
          type: 'setPrices',
          data: await doc.sheetsById[GOOGLE_PRICES_SHEET_ID].getRows()
        })

        dispatch({
          type: 'setPickupOptions',
          data: await doc.sheetsById[GOOGLE_DELIVERY_ID].getRows()
        })

        dispatch({
          type: 'setCouponList',
          data: await doc.sheetsById[GOOGLE_COUPONS_ID].getRows()
        })

        dispatch({
          type: 'setOrderCount',
          data: (await doc.sheetsById[GOOGLE_ORDERS_ID].getRows()).reduce((acc, curr) => {
            // count the number of orders per date
            if (
              curr['Date'] === '' ||
              curr['Date'] === undefined ||
              curr['Name'].toLowerCase() === 'total:' ||
              curr['Name'].toLowerCase() === 'total' ||
              curr['Name'] === undefined
            ) {
              return acc
            } else if (acc[curr['Date']] === undefined) {
              acc[curr['Date']] = 1
            } else if (acc[curr['Date']] > 0) {
              acc[curr['Date']] += 1
            }
            return acc
          }, {})
        })

        dispatch({
          type: 'setDate',
          data: nextOrderDates(weekly)[0]
        })
      } catch (e) {
        console.error('Error: ', e)
      } finally {
        dispatch({ type: 'loading', data: false })
      }
    }
    getGoogleSheets()
  }, [])

  return (
    // <div className={loading ? 'overlay--loading' : 'hide'}>
    <div className={'hide'}>
      <div className={loading ? 'loading' : 'hide'} aria-label='site is loading' />
    </div>
  )
}

export default GetSheets
