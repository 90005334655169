import box5 from '../assets/package/box5.JPG'
import macagram from '../assets/package/macagram.JPG'
import batch from '../assets/package/batch.JPG'
import bulk from '../assets/package/bulk.JPG'
import cookies8 from '../assets/package/cookies8.JPG'

export const productImages = {
  'Box of 5': box5,
  'MAC-A-GRAM': macagram,
  'Batch of 12': batch,
  'Catering + Bulk': bulk,
  'Cookies Batch of 8': cookies8
}
export const productAltText = {
  'Box of 5': 'a row of 5 macarons on their side placed inside a kraft paper box',
  'MAC-A-GRAM': '2 macarons stacked inside a clear cube shaped box',
  'Batch of 12': 'alternative rows of macaron top and bottom shells on a baking tray',
  'Catering + Bulk': 'bulk',
  'Cookies Batch of 8': 'a batch of chocolate chip cookies on a drying rack'
}
